import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Full website coming soon - please get in touch via twitter or email!
        </p>
      </header>
      <main className="App-main">
        <h1 class="App-Title">
          mintlink 
          <span> labs</span>
        </h1>
        <p class="App-Tagline">
          smart contract development | art generation | end-to-end nfts
        </p>
        <div class="App-contact">
          <a
            className="App-link"
            href="https://twitter.com/mintlinklabs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            className="App-link"
            href="mailto:mintlinklabs@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </a>
        </div>
      </main>
    </div>
  );
}

export default App;
